.door { 
    height: 36vh; 
    width: 20vh;
    background-color: black;
    position: absolute; 
    border-style: solid;
    border-color: white;
    box-shadow: -2px 2px 4px white;
    border-width: 3px;
}

.door::after {
    border-radius: 50%;
    height: 25px;
    width: 25px;
    color: red;
    background-color: red;
}

.doors .one { 
    left: 20%;
    top: 7%;
    transform: rotate(2deg);
}

.doors .two {
    right: 10%;
    top: 10%;
    height: calc(1.8*23vh);
    width: 23vh; 
    transform: rotate(-4deg)
}

.doors .three { 
    bottom: 10%;
    right: 24%;
    transform: rotate(8deg);
    
}

.doors .four { 
    bottom: 7%;
    left: 10%;
    transform: rotate(-10deg);
    height: calc(1.8*25vh);
    width: 25vh;
}

.doors .main { 
    height: 72vh;
    width: 40vh; 
    left: 40%;
    top: 20vh;
    background-color: white;
    border-color: black;
    box-shadow: -4px 4px 10px rgb(101, 101, 101);
}

.doors .door .knob { 
    height: 4%;
    display: block;
    width: 7.6%;
    border-radius: 50%;
    background-color: inherit;
    filter: invert(1);
    position: relative;
    top: 46%;
    left: 85%;
}

.door:hover { 
    filter: invert(1);
}


.doors .up { 
    color: white;
}

.raven { 
    position: absolute;
    bottom: 2%;
    right: 2%;
}