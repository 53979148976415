.wedding .script { 
    opacity: 1;
    margin-top: 2%;
}

.wedding .arrow { 
    color: white;
}

.wedding .right { 
    position: relative;
    top: 50vh;
    left: 80%;
}

.wedding .left { 
    position: relative;
    top: 50vh;
    left: 16%;
}


.wedding .script { 
    padding-bottom: 2%;
}

.wedding .instructions { 
    margin: 5%;
    margin-top: 3%;
    background-color: black;
    color: rgb(196, 6, 6);
    font-size: 18px;
    padding: 3%;
    text-align: center;
}

.wedding .puzzle {
    text-align: center;
}

.wedding .puzzle img { 
    height: 70vh;
}



.wedding .instructions .input input { 
    height: 40px;
    width: 200px;
}

.wedding .instructions .input .btn { 
    margin-left: 5%;
    color: rgb(196, 6, 6);
}

.wedding .instructions .input { 
    margin-top: 1%;
}

.wedding .instructions li {
    list-style-type: dot;
}
.wedding .puzzle .pagination li { 
    display: inline-block;
}

.wedding .puzzle .pagination li a {
    background-color: black;
    border-color: black;
    color: white;
}

.wedding .puzzle .pagination .page-item.active span { 
    background-color: white;
    color: black;
    border-color: white;
}

.wedding .puzzle .pagination { 
    display: flex;
    justify-content: center;
    margin-top: 2%;
}