.base { 
  background-size: cover;
  min-height: 100vh;
  font-family: scrivano, serif;
}

.btn.btn-primary { 
  background-color: #aaa9ad;
  color: black;
  border-color: black;
  border-radius: 10px;
  font-family: scrivano, serif;
  font-weight: 700;
  font-size: 25px;
  font-style: normal;
  
}

.btn.btn-primary:hover { 
  background-color: white;
  color: black;
  border-color: black;
}

.btn.btn-primary:active { 
  color: white !important;
  background-color: black !important;
}

input { 
  border-radius: 10px;
  border-style: none;
  background-color: #dad9d9;
  outline: none;
  text-align: center;
  font-family: scrivano, serif;
  font-weight: 400;
  font-style: normal;
}

input:focus::placeholder  { 
  color: transparent; 
}

input:focus { 
  background-color: white;
}

.up { 
  position: absolute;
  left: 50%;
  top: 5%;
  height: 30px;
}

.arrow { 
  color: black;
}
.arrow:hover { 
  animation: bounce 1s infinite;
 -webkit-animation: bounce 1s infinite; 
 -moz-animation: bounce 1s infinite;
 -o-animation: bounce 1s infinite; 
}

.finalclue { 
    display: flex;
    justify-content: center;
    align-items: center; 
    padding-top: 15vh;
}
.finalclue img { 
  height: 70vh;
}


@-webkit-keyframes bounce {
  0%,  
  100% {
     -webkit-transform: translateY(0);
  } 
  50% {
      -webkit-transform: translateY(-10px);
  }
}
@-moz-keyframes bounce {
  0%, 
  100% {
     -moz-transform: translateY(0);
  }
  50% {
     -moz-transform: translateY(-10px);
  }
}
@-o-keyframes bounce {
  0%,
  100% {
     -o-transform: translateY(0);
  }
  50% {
     -o-transform: translateY(-10px);
  }
}
@keyframes bounce {
  0%,  
  100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px);
  }
}

.timer { 
  color: red;
  position: absolute;
  top: 2%;
  left: 2%;
}