.modal-header { 
    text-align: center;
    display: block;
    
}

.modal { 
    font-family: scrivano, serif;
}

.card-header { 
    text-align: center;
    background-color: black;
}

.card-header .btn { 
    color: white;
}

.modal {
    height: 90vh;
    overflow: scroll;
}