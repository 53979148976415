.wall { 

    background-size: 100%;
    background-position: 0;
    padding-top: 1%;

}

.script { 
    padding: 3%;
    background-color: white;
    margin: 2%;
    padding-top: 1%;
    margin-bottom: 0;
    opacity: .9;
    text-align: center;
    margin-bottom: 0%;
}

.fade-enter {
    opacity: 0;
    transform: translateX(-100%);
}
.fade-enter-active {
    opacity: 1;
    transform: translateX(0%);
}
.fade-exit {
    opacity: 1;
    transform: translateX(0%);
}
.fade-exit-active {
    opacity: 0;
    transform: translateX(100%);
}
.fade-enter-active ,
.fade-exit-active {
    transition: opacity 500ms, transform 500ms;
}

.wall .right { 
    position: relative;
    top: 50vh;
    left: 80%;
    z-index: 1;
}

.wall .puzzle { 
    text-align: center;
}

.wall .left { 
    position: relative; 
    top: 50vh;
    left: 10%;
    z-index: 1;
}

.wall .puzzle input { 
    display: inline-block;
    text-align: center;
    align-self: center;
}

.wall .puzzle img { 
    float: left;
    text-align: center;
    width: 35%;
    margin-top: 5vh;
    margin-left: 20%;
}

.wall .puzzle .input { 
    float: right;
    margin-right: 25%;
    margin-top: 20vh;
    text-align: center;
}
.wall .puzzle .input input { 
    display: block;
    margin-bottom: 10%;
    height: 60px;
    font-size: 45px;
    width: 200px;
}

