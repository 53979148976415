
.login { 
    padding-top: 8%;
}

.login .row { 
    text-align: center;
    margin-bottom: 5vh;
}

.login input, .login .btn { 
    height: 50px;
    width: 200px;
}

.enter { 
    margin-bottom: 3%;
}
.login input { 
    font-size: 25px;
    font-family: scrivano, serif;
}
