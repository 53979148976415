.battle .right { 
    position: relative; 
    top: 50vh;
    left: 88%;
    z-index: 1;
}

.battle .left { 
    position: relative; 
    top: 50vh;
    left: 4%;
    z-index: 1;
}


.battle .script { 
    padding-bottom: 1%;
    margin-top: 1%;
}


.battle input { 
    width: 40%;
    height: 100%;
}

.battle table { 
    margin: 2%;
    margin-left: 10%;
    margin-bottom: 0%;
    width: 80%;
}

.battle td { 
    padding: 1%;
    text-align: center;
}

.battle .instructions { 
    text-align: center;
    margin: 3%;
    background-color: black;
    padding: 3%;
}

.battle .instructions li { 
    list-style-type: none;
    color: white;
    margin-top: .5em;
    font-size: 18px;
}


.battle .instructions .input input { 
    height: 40px;
    width: 200px;
}

.battle .instructions .input .btn { 
    margin-left: 5%;
}

.battle .instructions .input { 
    margin-top: 1%;
}

.battle td.divider {
    border-left-color: white;
    border-left-style: solid;
}

.battle tr.divider { 
    border-top-color: white;
    border-top-style: solid;
}

.battle td.titlerow { 
    border-top-style: none;
}