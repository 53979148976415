.sacrifice .right { 
    position: relative;
    top: 50vh;
    left: 80%;
    z-index: 1;
}

.sacrifice .puzzle { 
    text-align: center;
}

.sacrifice .left { 
    position: relative; 
    top: 50vh;
    left: 10%;
    z-index: 1;
}

.sacrifice .puzzle input { 
    display: inline-block;
    text-align: center;
    align-self: center;
}

.sacrifice .puzzle img { 
    height: 80vh;
    margin-left: 20%;
}

.sacrifice .puzzle .input { 
    float: right;
    margin-right: 25%;
    margin-top: 20vh;
    text-align: center;
}
.sacrifice .puzzle .input input { 
    display: block;
    margin-bottom: 10%;
    height: 60px;
    font-size: 45px;
    width: 200px;
}

.sacrifice .up { 
    color: white;
}