.final { 
    height: 100vh;
    background-color: white;
    text-align: center;
}

.final .password { 
    text-align: center;
    width: 60%;
    background-color: black;
    padding: 10%;
    margin-top: 12vh;
    height: 80vh;
    margin-left: 20%;
}

.final .password input { 
    height: 10vh;
    margin-top: 2%;
    width: calc(10vh*8);
}

.final .btn { 
    margin-top: 3%;
}

.password  img { 
    width: 60%;
}