.home { 
    text-align: center;
    padding-top: 6%;
}

.home h1 { 
    font-family: scrivano, serif;
    color: whitesmoke;
    font-weight: 600;
}


.notification, .teammates { 
    margin: 4%;
    padding: 6%;
    background-color: silver;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 1px;
    word-spacing: 6px;
}

#roomid { 
    color: goldenrod;
    background-color: black;
    padding: 1.5%;
}